import React from 'react';

class InputTextarea extends React.Component {

    render() {
        return <div className="form-group">
            {typeof this.props.label === "string" && <label className="form-label">{this.props.label}</label>}
            <textarea className={this.getInputClassName()}
                      value={this.getValue()}
                      onChange={this.onChange.bind(this)}
                      onKeyPress={this.onKeyPress.bind(this)}
                      disabled={this.props.disabled}
                      rows={this.props.rows}/>
            {Array.isArray(this.props.errors) && this.props.errors.map(error => <div key={error}
                                                                                     className="alert alert-danger"
                                                                                     role="alert">
                {error}
            </div>)}
        </div>;
    }

    getInputClassName() {
        const classNames = ["form-control"];
        if (this.props.errors && this.props.errors.length > 0) {
            classNames.push("is-invalid");
        }
        return classNames.join(" ");
    }

    getValue() {
        if (this.props.name && this.props.parent) {
            const propertyNames = this.props.name.split(".");
            let state = this.props.parent.state;
            let property = state;
            for (let i = 0; i < propertyNames.length; ++i) {
                property = property[propertyNames[i]];
            }
            return typeof property === "string" ? property : "";
        } else {
            return typeof this.props.value === "string" ? this.props.value : "";
        }
    }

    onChange(event) {
        const value = event.target.value;
        if (this.props.name && this.props.parent) {
            const propertyNames = this.props.name.split(".");
            let state = this.props.parent.state;
            let property = state;
            for (let i = 0; i < propertyNames.length - 1; ++i) {
                property = property[propertyNames[i]];
            }
            property[propertyNames[propertyNames.length - 1]] = value;
            this.props.parent.setState(state);
        }
        if (typeof this.props.onChange === "function") {
            this.props.onChange(value);
        }
    }

    onKeyPress(event) {
        if (event.key === "Enter" && typeof this.props.onEnter === "function") {
            this.props.onEnter();
        }
    }

}

export default InputTextarea;
