import React from 'react';
import AppContext from "../AppContext";

class Footer extends React.Component {

    render() {
        return <main role="main" className="container">
            <p>System jest nieaktywny, proszę o cierpliwość lub bezpośredni
                kontakt z
                administratorem.</p>
        </main>;
    }

}

Footer.contextType = AppContext;

export default Footer;
