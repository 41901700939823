import React from 'react';

class FilterText extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            value: this.props.value
        };
    }

    render() {
        return <input type="text" className="form-control"
                   value={this.state.value}
                   onChange={this.onChange.bind(this)}/>;
    }

    onChange(event) {
        const value = event.target.value;
        this.setState({value: value});
        this.props.onChange(this.props.property, value);
    }

}

export default FilterText;
