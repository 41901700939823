import React from 'react';
import AppContext from '../../../AppContext';
import Table from "../../../component/view/Table";
import Modal from "../../../component/modal/Modal";
import InputText from "../../../component/form/InputText";

const MODE_DEFAULT = 0;
const MODE_UPDATE = 1;

class ContainerNotificationTemplates extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            mode: MODE_DEFAULT,
            notificationTemplates: [],
            offset: 0,
            limit: process.env.REACT_APP_PAGE_LIMIT,
            pageFirst: null,
            pagePrevious: null,
            pageNext: null,
            pageLast: null
        };
        this.tableElement = React.createRef();
    }

    render() {
        return <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Szablony powiadomień</h1>
            </div>
            <div className="table-responsive">
                <Table columns={[
                    {
                        label: "Nazwa",
                        renderValue: notificationTemplate => notificationTemplate.name
                    }, {
                        label: "Szablon tytułu",
                        renderValue: notificationTemplate => notificationTemplate.titleTemplate.content
                    }, {
                        label: "Szablon tekstowy",
                        renderValue: notificationTemplate => notificationTemplate.textContentTemplate.content
                    }, {
                        label: "Szablon HTML",
                        renderValue: notificationTemplate => notificationTemplate.htmlContentTemplate.content
                    }, {
                        label: "",
                        renderValue: notificationTemplate => <div className="btn-group" role="group" aria-label="Akcje">
                            <button type="button" className="btn btn-primary"
                                    onClick={this.startUpdateNotificationTemplate.bind(this, notificationTemplate)}>
                                Zmień
                            </button>
                        </div>
                    }
                ]} objectsUrl="notificationTemplates" changesUrl="changes" ref={this.tableElement}/>
            </div>
            {this.state.mode === MODE_UPDATE && <Modal
                title="Zmień szablon powiadomień"
                onCancel={this.cancel.bind(this)}
                onConfirm={this.updateNotificationTemplate.bind(this)}
                cancelTitle="Anuluj"
                confirmTitle="Zapisz">
                <form>
                    <InputText label="Szablon tytułu" name="notificationTemplate.titleTemplate" parent={this}/>
                    <InputText label="Szablon tekstowy" name="notificationTemplate.textContentTemplate" parent={this}/>
                    <InputText label="Szablon HTML" name="notificationTemplate.htmlContentTemplate" parent={this}/>
                </form>
            </Modal>}
        </main>;
    }

    startUpdateNotificationTemplate(notificationTemplate) {
        this.setState({
            mode: MODE_UPDATE,
            notificationTemplate: {
                id: notificationTemplate.id,
                titleTemplate: notificationTemplate.titleTemplate.content,
                textContentTemplate: notificationTemplate.textContentTemplate.content,
                htmlContentTemplate: notificationTemplate.htmlContentTemplate.content
            }
        });
    }

    cancel() {
        this.setState(state => ({
            mode: MODE_DEFAULT,
            notificationTemplates: state.notificationTemplates,
            offset: state.offset,
            limit: state.limit,
            pageFirst: state.pageFirst,
            pagePrevious: state.pagePrevious,
            pageNext: state.pageNext,
            pageLast: state.pageLast
        }));
    }

    updateNotificationTemplate() {
        this.setState({
            mode: MODE_DEFAULT
        });
        const notificationTemplate = this.state.notificationTemplate;
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/notificationTemplates/" + this.state.notificationTemplate.id, {
            method: "PUT", headers: {
                Authorization: "Bearer " + this.context.sessionId, "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            }, body: JSON.stringify(notificationTemplate)
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    this.tableElement.current.loadObjects();
                } else {
                    throw Error(result.statusText);
                }
            });
    }

}

ContainerNotificationTemplates.contextType = AppContext;

export default ContainerNotificationTemplates;
