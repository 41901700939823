import React from 'react';

import Header from "./dashboard/Header";
import Container from "./dashboard/Container";
import {HashRouter} from "react-router-dom";

class Dashboard extends React.Component {

    componentDidMount() {
        document.body.classList.add("dashboard");
        document.body.classList.add("h-100");
    }

    componentWillUnmount() {
        document.body.classList.remove("dashboard");
        document.body.classList.remove("h-100");
    }

    render() {
        return <div>
            <HashRouter>
                <Header onLogoutSuccess={this.props.onLogoutSuccess}/>
                <Container/>
            </HashRouter>
        </div>;
    }

}

export default Dashboard;
