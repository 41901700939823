import React from 'react';
import AppContext from "../AppContext";

const MODE_DEFAULT = 0;
const MODE_REMIND_PASSWORD = 1;
const MODE_REMIND_PASSWORD_REQUESTED = 2;

class SignIn extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: MODE_DEFAULT,
            email: "",
            password: ""
        };
    }

    componentDidMount() {
        document.body.classList.add("signin");
        document.body.classList.add("text-center");
    }

    componentWillUnmount() {
        document.body.classList.remove("signin");
        document.body.classList.remove("text-center");
    }

    render() {
        return <main className="form-signin">
            {this.state.mode === MODE_DEFAULT && <form>
                {/*<img className="mb-4" src={logo} alt="" width="72" height="57"/>*/}
                <img className="mb-4" src="logo512.png" alt="" width="72" height="57"/>
                <h1 className="h3 mb-3 fw-normal">{this.context.messages["users.actions.logIn.name"]}</h1>
                {
                    this.state.error && <div className="alert alert-danger" role="alert">
                        {this.state.error}
                    </div>
                }
                <label htmlFor="inputName" className="visually-hidden">Email</label>
                <input type="text" id="inputName" className="form-control" placeholder="Email" required
                       autoFocus value={this.state.email} onChange={this.setEmail.bind(this)}
                       onKeyPress={this.onKeyPress.bind(this)}/>
                <label htmlFor="inputPassword" className="visually-hidden">Hasło</label>
                <input type="password" id="inputPassword" className="form-control" placeholder="Hasło" required
                       value={this.state.password} onChange={this.setPassword.bind(this)}
                       onKeyPress={this.onKeyPress.bind(this)}/>
                <a href="/" onClick={this.startRequestPasswordReminder.bind(this)}>Nie pamiętam hasła</a>
                <button className="w-100 btn btn-lg btn-primary" type="button" onClick={this.login.bind(this)}>
                    {this.context.messages["users.actions.logIn.name"]}
                </button>
            </form>}
            {this.state.mode === MODE_REMIND_PASSWORD && <form>
                <img className="mb-4" src="logo512.png" alt="" width="72" height="57"/>
                <h1 className="h3 mb-3 fw-normal">Poproś o nowe hasło</h1>
                {
                    this.state.error && <div className="alert alert-danger" role="alert">
                        {this.state.error}
                    </div>
                }
                <label htmlFor="inputName" className="visually-hidden">Email</label>
                <input type="text" id="inputName" className="form-control" placeholder="Email" required
                       autoFocus value={this.state.email} onChange={this.setEmail.bind(this)}
                       onKeyPress={this.onKeyPress.bind(this)}/>
                <button className="w-100 btn btn-lg btn-primary" type="button"
                        onClick={this.requestPasswordReminder.bind(this)}>
                    Wyślij prośbę
                </button>
            </form>}
            {this.state.mode === MODE_REMIND_PASSWORD_REQUESTED && <form>
                <img className="mb-4" src="logo512.png" alt="" width="72" height="57"/>
                <h1 className="h3 mb-3 fw-normal">Poproś o nowe hasło</h1>
                <div className="alert alert-info" role="alert">
                    Prośba o nowe hasło została wysłana.
                    Oczekuj na link do ustawienia nowego hasła na swojej skrzynce pocztowej.
                </div>
            </form>}
        </main>;
    }

    setEmail(event) {
        let state = this.state;
        state.email = event.target.value;
        this.setState(state);
    }

    setPassword(event) {
        let state = this.state;
        state.password = event.target.value;
        this.setState(state);
    }

    onKeyPress(event) {
        if (event.key === "Enter") {
            this.login();
        }
    }

    login() {
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/sessions", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            },
            body: JSON.stringify({
                email: this.state.email,
                password: this.state.password
            })
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    return result.json();
                } else {
                    throw Error(result.statusText);
                }
            })
            .then(result => this.props.onLoginSuccess(result.id))
            .catch(this.handleError.bind(this));
    }

    startRequestPasswordReminder(event) {
        this.setState({
            mode: MODE_REMIND_PASSWORD
        });
        event.preventDefault();
    }

    requestPasswordReminder() {
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/passwordReminders/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            },
            body: JSON.stringify({
                email: this.state.email
            })
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    this.setState({
                        mode: MODE_REMIND_PASSWORD_REQUESTED
                    })
                } else {
                    throw Error(result.statusText);
                }
            });
    }

    handleError() {
        this.setState({
            email: "",
            password: "",
            error: "Nieprawidłowy email lub hasło"
        });
    }

}

SignIn.contextType = AppContext;

export default SignIn;
