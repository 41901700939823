import React from 'react';
import Modal from "../modal/Modal";
import Table from "../view/Table";

const MODE_DEFAULT = 0;
const MODE_SELECT = 1;

class InputObject extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            mode: MODE_DEFAULT,
            objects: [],
            offset: 0,
            limit: process.env.REACT_APP_PAGE_LIMIT,
            pageFirst: null,
            pagePrevious: null,
            pageNext: null,
            pageLast: null
        };
    }

    render() {
        return <div className="form-group">
            <label className="form-label">{this.props.label}</label>
            <div className="input-group mb-3">
                <input type="text" className={this.getInputClassName()}
                       value={this.getValue()}
                       readOnly={true}/>
                <div className="input-group-append">
                    <button type="button" className="btn btn-primary" onClick={this.startSelect.bind(this)}>...</button>
                </div>
            </div>
            {Array.isArray(this.props.errors) && this.props.errors.map(error => <div key={error}
                                                                                     className="alert alert-danger"
                                                                                     role="alert">
                {error}
            </div>)}
            {this.state.mode === MODE_SELECT && <Modal
                title={this.props.modalTitle}
                onCancel={this.cancelSelect.bind(this)}
                cancelTitle="Anuluj"
                size="modal-lg">
                <Table columns={this.getTableColumns()} objectsUrl={this.props.objectsUrl}/>
            </Modal>}
        </div>;
    }

    getInputClassName() {
        const classNames = ["form-control"];
        if (this.props.errors && this.props.errors.length > 0) {
            classNames.push("is-invalid");
        }
        return classNames.join(" ");
    }

    getValue() {
        if (this.props.name && this.props.parent) {
            const propertyNames = this.props.name.split(".");
            let state = this.props.parent.state;
            let property = state;
            for (let i = 0; i < propertyNames.length; ++i) {
                property = property[propertyNames[i]];
            }
            return typeof this.props.renderValue === "function" ? this.props.renderValue(property) : property;
        } else {
            return this.props.value;
        }
    }

    startSelect() {
        this.setState({mode: MODE_SELECT});
    }

    cancelSelect() {
        this.setState({mode: MODE_DEFAULT});
    }

    getTableColumns() {
        const tableColumns = [...this.props.tableColumns];
        tableColumns.push({
            label: "",
            renderValue: object => <button type="button" className="btn btn-primary"
                                           onClick={this.select.bind(this, object)}>
                Wybierz
            </button>
        });
        return tableColumns;
    }

    select(object) {
        this.setValue(object);
        this.setState({mode: MODE_DEFAULT});
    }

    setValue(value) {
        if (this.props.name && this.props.parent) {
            const propertyNames = this.props.name.split(".");
            let state = this.props.parent.state;
            let property = state;
            for (let i = 0; i < propertyNames.length - 1; ++i) {
                property = property[propertyNames[i]];
            }
            property[propertyNames[propertyNames.length - 1]] = value;
            this.props.parent.setState(state);
        }
        if (typeof this.props.onChange === "function") {
            this.props.onChange(value);
        }
    }

}

export default InputObject;
