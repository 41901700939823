import React from 'react';
import AppContext from '../../../AppContext';
import Modal from "../../../component/modal/Modal";
import InputObject from "../../../component/form/InputObject";
import InputTextareaWithCopyableFragments from "../../../component/form/InputTextareaWithCopyableFragments";
import List from "../../../component/view/List";
import InputTextarea from "../../../component/form/InputTextarea";
import {Badge, OverlayTrigger, Tooltip} from "react-bootstrap";
import uniqueId from "lodash.uniqueid";

const MODE_DEFAULT = 0;
const MODE_CONSULT_EXPERTISE = 1;
const MODE_DO_EXPERTISE = 2;
const MODE_CHANGE_EXPERTISE = 3;
const MODE_BLOCK_EXPERTISE = 4;
const MODE_SHARE_EXPERTISE = 5;
const MODE_DELETE_EXPERTISE = 6;
const MODE_VIEW_CONSULTATIONS = 7;

class ContainerExpertises extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            mode: MODE_DEFAULT,
            consultants: [],
            expertises: [],
            offset: 0,
            limit: process.env.REACT_APP_PAGE_LIMIT,
            pageFirst: null,
            pagePrevious: null,
            pageNext: null,
            pageLast: null
        };
        this.tableElement = React.createRef();
    }

    loadConsultants(query) {
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/expertises/consultants?query=" + query + "&limit=" + process.env.REACT_APP_PAGE_LIMIT, {
            headers: {
                Authorization: "Bearer " + this.context.sessionId, "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            }
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    return result.json();
                } else {
                    throw Error(result.statusText);
                }
            })
            .then(result => {
                this.setState({consultants: result});
            });
    }

    loadExpertise(expertiseId) {
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/expertises/" + expertiseId, {
            headers: {
                Authorization: "Bearer " + this.context.sessionId, "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            }
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    return result.json();
                } else {
                    throw Error(result.statusText);
                }
            })
            .then(result => {
                this.setState({
                    expertise: result
                });
            });
    }

    loadExpertiseConsultations(expertiseId) {
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/expertises/" + expertiseId + "/consultations?offset=" + this.state.expertiseConsultations.offset + "&limit=" + this.state.expertiseConsultations.limit, {
            headers: {
                Authorization: "Bearer " + this.context.sessionId, "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            }
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    return result.json();
                } else {
                    throw Error(result.statusText);
                }
            })
            .then(result => {
                this.setState({
                    expertiseConsultations: {
                        mode: MODE_DEFAULT,
                        consultations: result,
                        offset: 0,
                        limit: process.env.REACT_APP_PAGE_LIMIT,
                        pageFirst: null,
                        pagePrevious: null,
                        pageNext: null,
                        pageLast: null
                    }
                });
            });
    }

    render() {
        return <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Pytania</h1>
            </div>
            <List filters={this.getListFilters()} defaultFilters={{status: ["WAITING", "WAITING_FOR_CONSULTATION"]}}
                  sortItems={[
                      {
                          sort: "createdTime",
                          label: "rosnąco wg daty",
                          descending: false
                      }, {
                          sort: "createdTime",
                          label: "malejąco wg daty",
                          descending: true
                      }
                  ]}
                  objectRenderer={expertise => <div className="w-100">
                      <div className="row">
                          <div className="col-11">{expertise.question.split("\n").map(line => <p
                              className="mb-1">{line}</p>)}</div>
                          <div className="col-1">{expertise.createdDate} <span
                              className="badge bg-primary rounded-pill">{expertise.questionVisitsCount}</span></div>
                      </div>
                      <small>{expertise.authorName}</small>
                      {this.renderEmail(expertise.email)}
                      <p className="mb-1">
                          {this.getStatuses(expertise)}
                      </p>
                      <div className="btn-group" role="group" aria-label="Akcje">
                          {expertise.answer === null && !expertise.blocked &&
                              <button type="button" className="btn btn-primary"
                                      onClick={this.startConsultExpertise.bind(this, expertise)}>
                                  {this.context.messages["expertises.actions.consult.name"]}
                              </button>}
                          {!expertise.done && !expertise.blocked && <button type="button" className="btn btn-primary"
                                                                            onClick={this.startDoExpertise.bind(this, expertise)}>
                              {this.context.messages["expertises.actions.answer.name"]}
                          </button>}
                          {expertise.done && !expertise.blocked && <button type="button" className="btn btn-primary"
                                                                           onClick={this.startChangeExpertise.bind(this, expertise)}>
                              {this.context.messages["expertises.actions.change.name"]}
                          </button>}
                          {expertise.done && !expertise.blocked && <button type="button" className="btn btn-primary"
                                                                           onClick={this.startBlockExpertise.bind(this, expertise)}>
                              {this.context.messages["expertises.actions.block.name"]}
                          </button>}
                          {expertise.done && <button type="button" className="btn btn-primary"
                                                     onClick={this.startShareExpertise.bind(this, expertise)}>
                              {this.context.messages["expertises.actions.share.name"]}
                          </button>}
                          <button type="button" className="btn btn-primary"
                                  onClick={this.startViewExpertise.bind(this, expertise.id)}>
                              Pokaż konsultacje
                          </button>
                          <button type="button" className="btn btn-primary"
                                  onClick={this.startDeleteExpertise.bind(this, expertise)}>
                              {this.context.messages["expertises.actions.delete.name"]}
                          </button>
                      </div>
                  </div>}
                  objectsUrl="expertises" changesUrl="changes" ref={this.tableElement}
            />
            {this.state.mode === MODE_CONSULT_EXPERTISE && <Modal
                title={this.context.messages["expertises.actions.consult.name"]}
                onCancel={this.cancel.bind(this)}
                onConfirm={this.consultExpertise.bind(this)}
                cancelTitle="Anuluj"
                confirmTitle={this.context.messages["expertises.actions.consult.confirm"]}
                size="modal-lg">
                <form>
                    <InputObject label="Konsultant" name="expertiseConsultation.consultant" parent={this}
                                 renderValue={consultant => consultant ? consultant.displayName : ""}
                                 tableColumns={this.getConsultantTableColumns()}
                                 modalTitle="Wybierz konsultanta"
                                 objectsUrl="expertises/consultants"/>
                    <InputTextarea label="Pytanie" name="expertiseConsultation.question" parent={this} rows={10}/>
                </form>
            </Modal>}
            {this.state.mode === MODE_DO_EXPERTISE && <Modal
                title={this.context.messages["expertises.actions.answer.name"]}
                actions={[
                    {
                        className: "secondary",
                        onClick: this.cancel.bind(this),
                        title: "Anuluj"
                    }, {
                        className: "primary",
                        onClick: this.sketchExpertise.bind(this),
                        title: "Zapisz szkic"
                    }, {
                        className: "primary",
                        onClick: this.doExpertise.bind(this),
                        title: this.context.messages["consultations.actions.answer.confirm"]
                    }
                ]}
                size="modal-lg">
                <form>
                    <div className="mb-3">
                        <InputTextarea label="Pytanie" name="expertise.question" parent={this} disabled={true} rows={10}/>
                        <InputTextareaWithCopyableFragments label="Odpowiedź" name="expertise.answer" parent={this} rows={10}
                                                            fragmentsUrl={"expertises/" + this.state.expertise.id + "/doneConsultations"}
                                                            fragmentIdExtractor={function (expertiseConsultation) {
                                                                return expertiseConsultation.id;
                                                            }}
                                                            fragmentContentExtractor={function (expertiseConsultation) {
                                                                return expertiseConsultation.answer;
                                                            }}
                                                            fragmentTitleFormatter={function (expertiseConsultation) {
                                                                return expertiseConsultation.consultantDisplayName + ": " + expertiseConsultation.question;
                                                            }}
                                                            fragmentContentFormatter={function (expertiseConsultation) {
                                                                return expertiseConsultation ? expertiseConsultation.answer : "(nie wybrano odpowiedzi konsultanta)";
                                                            }}
                                                            copyFragmentTitle="Kopiuj odpowiedź"
                        />
                    </div>
                </form>
            </Modal>}
            {this.state.mode === MODE_CHANGE_EXPERTISE && <Modal
                title={this.context.messages["expertises.actions.change.name"]}
                onCancel={this.cancel.bind(this)}
                onConfirm={this.changeExpertise.bind(this)}
                cancelTitle="Anuluj"
                confirmTitle={this.context.messages["expertises.actions.change.confirm"]}
                size="modal-lg">
                <form>
                    <div className="mb-3">
                        <InputTextareaWithCopyableFragments label="Odpowiedź" name="expertise.answer" parent={this}
                                                            fragmentsUrl={"expertises/" + this.state.expertise.id + "/doneConsultations"}
                                                            fragmentIdExtractor={function (expertiseConsultation) {
                                                                return expertiseConsultation.id;
                                                            }}
                                                            fragmentContentExtractor={function (expertiseConsultation) {
                                                                return expertiseConsultation.answer;
                                                            }}
                                                            fragmentTitleFormatter={function (expertiseConsultation) {
                                                                return expertiseConsultation.consultantDisplayName + ": " + expertiseConsultation.question;
                                                            }}
                                                            fragmentContentFormatter={function (expertiseConsultation) {
                                                                return expertiseConsultation ? expertiseConsultation.answer : "(nie wybrano odpowiedzi konsultanta)";
                                                            }}
                                                            copyFragmentTitle="Kopiuj odpowiedź"
                        />
                    </div>
                </form>
            </Modal>}
            {this.state.mode === MODE_BLOCK_EXPERTISE && <Modal
                title={this.context.messages["expertises.actions.block.name"]}
                onCancel={this.cancel.bind(this)}
                onConfirm={this.blockExpertise.bind(this)}
                cancelTitle="Anuluj"
                confirmTitle={this.context.messages["expertises.actions.block.confirm"]}>
                <form>
                    <div className="mb-3">
                        {this.context.messages["expertises.actions.block.message"]}
                    </div>
                </form>
            </Modal>}
            {this.state.mode === MODE_SHARE_EXPERTISE && <Modal
                title={this.context.messages["expertises.actions.share.name"]}
                onCancel={this.cancel.bind(this)}
                onConfirm={this.shareExpertise.bind(this)}
                cancelTitle="Anuluj"
                confirmTitle={this.context.messages["expertises.actions.share.confirm"]}>
                <form>
                    <InputObject label="Użytkownik" name="expertise.user" parent={this}
                                 renderValue={user => user ? user.displayName : ""}
                                 tableColumns={this.getUserTableColumns()}
                                 modalTitle="Wybierz użytkownika"
                                 objectsUrl={"expertises/" + this.state.expertise.id + "/users"}/>
                </form>
            </Modal>}
            {this.state.mode === MODE_DELETE_EXPERTISE && <Modal
                title={this.context.messages["expertises.actions.delete.name"]}
                onCancel={this.cancel.bind(this)}
                onConfirm={this.deleteExpertise.bind(this)}
                cancelTitle="Anuluj"
                confirmTitle={this.context.messages["expertises.actions.delete.confirm"]}>
                <form>
                    <div className="mb-3">
                        {this.context.messages["expertises.actions.delete.message"]}
                    </div>
                </form>
            </Modal>}
            {this.state.mode === MODE_VIEW_CONSULTATIONS && <Modal
                title="Konsultacje"
                onCancel={this.cancel.bind(this)}
                onConfirm={this.cancel.bind(this)}
                cancelTitle="Anuluj"
                confirmTitle="Zamknij"
                size="modal-lg">
                <List filters={[
                    {
                        name: "Status",
                        type: "enums",
                        property: "status",
                        items: [
                            {
                                value: "WAITING",
                                label: "Wymaga odpowiedzi"
                            }, {
                                value: "CANCELLED",
                                label: "Anulowano"
                            }, {
                                value: "DONE",
                                label: "Udzielono odpowiedzi"
                            }
                        ],
                        value: ["DONE"]
                    }
                ]} defaultFilters={{status: ["DONE"]}}
                      sortItems={[
                          {
                              sort: "started",
                              label: "rosnąco wg daty",
                              descending: false
                          }, {
                              sort: "started",
                              label: "malejąco wg daty",
                              descending: true
                          }
                      ]}
                      objectRenderer={consultation => <div className="w-100">
                          <div className="d-flex justify-content-between align-items-center">
                              <div>{consultation.question.split("\n").map(line => <p className="mb-1">{line}</p>)}</div>
                          </div>
                          {consultation.answer && <hr/>}
                          {consultation.answer && consultation.answer.split("\n").map(line => <p
                              className="mb-1">{line}</p>)}
                          <p className="mb-1">
                              {this.getConsultationStatuses(consultation)}
                          </p>
                      </div>}
                      objectsUrl={"expertises/" + this.state.expertiseId + "/consultations"}/>
            </Modal>}
        </main>;
    }

    getListFilters() {
        const filters = [{
            name: "Imię autora",
            type: "text",
            property: "authorName"
        }];
        if (this.context.currentUser.roles.includes("ADMINISTRATOR")) {
            filters.push({
                name: "Email",
                type: "text",
                property: "email"
            });
        }
        filters.push({
            name: "Pytanie",
            type: "text",
            property: "question"
        }, {
            name: "Odpowiedź",
            type: "text",
            property: "answer"
        }, {
            name: "Status",
            type: "enums",
            property: "status",
            items: [
                {
                    value: "WAITING",
                    label: "Wymaga odpowiedzi"
                }, {
                    value: "SKETCHED",
                    label: "Naszkicowano odpowiedź"
                }, {
                    value: "DONE",
                    label: "Udzielono odpowiedzi"
                }, {
                    value: "WAITING_FOR_CONSULTATION",
                    label: "W trakcie konsultacji"
                }, {
                    value: "CONSULTED",
                    label: "Skonsultowano"
                }, {
                    value: "BLOCKED",
                    label: "Zablokowano"
                }, {
                    value: "SHARED",
                    label: "Udostępniono"
                }
            ],
            value: ["WAITING", "WAITING_FOR_CONSULTATION"]
        }, {
            name: "Liczba odwiedzin",
            type: "integerRange",
            property: "questionVisitsCount"
        });
        return filters;
    }

    renderEmail(email) {
        return email ? <small> (<a href={"mailto:" + email}>{email}</a>)</small> : null;
    }

    getConsultantTableColumns() {
        let columns = [
            {
                label: "Wyświetlana nazwa",
                filter: {
                    type: "text",
                    property: "displayName"
                },
                renderValue: consultant => consultant.displayName
            }, {
                label: "Email",
                filter: {
                    type: "text",
                    property: "email"
                },
                renderValue: consultant => consultant.email
            }, {
                label: "Zajętość",
                renderValue: consultant => consultant.occupancy
            }
        ];
        if (this.context.currentUser.roles.includes("ADMINISTRATOR")) {
            columns.push({
                label: "Komentarz",
                renderValue: user => user.comment
            });
        }
        return columns;
    }

    getUserTableColumns() {
        let columns = [
            {
                label: "Wyświetlana nazwa",
                filter: {
                    type: "text",
                    property: "displayName"
                },
                renderValue: user => user.displayName
            }, {
                label: "Email",
                filter: {
                    type: "text",
                    property: "email"
                },
                renderValue: user => user.email
            }
        ];
        if (this.context.currentUser.roles.includes("ADMINISTRATOR")) {
            columns.push({
                label: "Komentarz",
                renderValue: user => user.comment
            });
        }
        return columns;
    }

    shorted(value) {
        if (value) {
            const lines = value.split("\n");
            return lines.length > 1 ? lines[0] + " (...)" : lines[0];
        } else {
            return "-";
        }
    }

    getStatuses(expertise) {
        const statuses = [];
        if (expertise.statuses.includes("WAITING")) {
            statuses.push(<span className="badge bg-danger">Wymaga odpowiedzi</span>);
        }
        if (expertise.statuses.includes("SKETCHED")) {
            statuses.push(<span className="badge info">Naszkicowano odpowiedź</span>);
        }
        if (expertise.statuses.includes("DONE")) {
            statuses.push(<span className="badge bg-success">Udzielono odpowiedzi</span>);
        }
        if (expertise.statuses.includes("WAITING_FOR_CONSULTATION")) {
            statuses.push(<span
                className="badge bg-warning">W trakcie konsultacji ({expertise.consultationDoneCount}/{expertise.consultationStartedCount - expertise.consultationCancelledCount})</span>);
        }
        if (expertise.statuses.includes("CONSULTED")) {
            statuses.push(<span className="badge bg-info">Skonsultowano</span>);
        }
        if (expertise.statuses.includes("BLOCKED")) {
            statuses.push(<span className="badge bg-danger">Zablokowano</span>);
        }
        if (expertise.statuses.includes("SHARED")) {
            const tooltip = <Tooltip id={uniqueId("tooltip-")}>
                {expertise.sharedUsers.map(user => " - " + user.displayName)}
            </Tooltip>
            statuses.push(<OverlayTrigger overlay={tooltip}>
                <Badge bg="info">Udostępniono</Badge>
            </OverlayTrigger>);
        }
        return <h6>{statuses}</h6>;
    }

    getConsultationStatuses(consultation) {
        const statuses = [];
        if (!consultation.cancelled && !consultation.done) {
            statuses.push(<span className="badge bg-danger">Wymaga odpowiedzi</span>);
        }
        if (consultation.cancelled) {
            statuses.push(<span className="badge bg-danger">Anulowano</span>);
        }
        if (consultation.done) {
            statuses.push(<span className="badge bg-success">Udzielono odpowiedzi</span>);
        }
        return <h6>{statuses}</h6>;
    }

    startViewExpertise(expertiseId) {
        this.setState({
            mode: MODE_VIEW_CONSULTATIONS,
            expertise: null,
            expertiseId: expertiseId,
            expertiseConsultations: {
                mode: MODE_DEFAULT,
                consultations: [],
                offset: 0,
                limit: process.env.REACT_APP_PAGE_LIMIT,
                pageFirst: null,
                pagePrevious: null,
                pageNext: null,
                pageLast: null
            }
        }, () => this.loadExpertise(expertiseId));
    }

    startConsultExpertise(expertise) {
        this.loadConsultants("");
        this.setState({
            mode: MODE_CONSULT_EXPERTISE,
            expertise: {
                id: expertise.id,
            },
            expertiseConsultation: {
                consultant: null,
                question: expertise.question
            }
        });
    }

    startDoExpertise(expertise) {
        this.setState({
            mode: MODE_DO_EXPERTISE,
            expertise: {
                id: expertise.id, question: expertise.question, answer: expertise.answer
            }
        });
    }

    startChangeExpertise(expertise) {
        this.setState({
            mode: MODE_CHANGE_EXPERTISE,
            expertise: {
                id: expertise.id, question: expertise.question, answer: expertise.answer
            }
        });
    }

    startBlockExpertise(expertise) {
        this.setState({
            mode: MODE_BLOCK_EXPERTISE,
            expertise: expertise
        });
    }

    startShareExpertise(expertise) {
        this.setState({
            mode: MODE_SHARE_EXPERTISE,
            expertise: expertise
        });
    }

    startDeleteExpertise(expertise) {
        this.setState({
            mode: MODE_DELETE_EXPERTISE,
            expertise: expertise
        });
    }

    cancel() {
        this.setState({
            mode: MODE_DEFAULT
        });
    }

    consultExpertise() {
        this.setState({
            mode: MODE_DEFAULT
        });
        const expertiseConsultation = this.state.expertiseConsultation;
        expertiseConsultation.consultantId = expertiseConsultation.consultant.id;
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/expertises/" + this.state.expertise.id + "/expertiseConsultations", {
            method: "POST", headers: {
                Authorization: "Bearer " + this.context.sessionId, "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            }, body: JSON.stringify(expertiseConsultation)
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    this.tableElement.current.loadObjects();
                } else {
                    throw Error(result.statusText);
                }
            });
    }

    sketchExpertise() {
        this.setState({
            mode: MODE_DEFAULT
        });
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/expertises/" + this.state.expertise.id + "/expertiseSketch", {
            method: "POST", headers: {
                Authorization: "Bearer " + this.context.sessionId, "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            }, body: JSON.stringify(this.state.expertise)
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    this.tableElement.current.loadObjects();
                } else {
                    throw Error(result.statusText);
                }
            });
    }

    doExpertise() {
        this.setState({
            mode: MODE_DEFAULT
        });
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/expertises/" + this.state.expertise.id + "/expertiseAnswer", {
            method: "POST", headers: {
                Authorization: "Bearer " + this.context.sessionId, "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            }, body: JSON.stringify(this.state.expertise)
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    this.tableElement.current.loadObjects();
                } else {
                    throw Error(result.statusText);
                }
            });
    }

    changeExpertise() {
        this.setState({
            mode: MODE_DEFAULT
        });
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/expertises/" + this.state.expertise.id + "/expertiseAnswer", {
            method: "PUT", headers: {
                Authorization: "Bearer " + this.context.sessionId, "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            },
            body: JSON.stringify(this.state.expertise)
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    this.tableElement.current.loadObjects();
                } else {
                    throw Error(result.statusText);
                }
            });
    }

    blockExpertise() {
        this.setState({
            mode: MODE_DEFAULT
        });
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/expertises/" + this.state.expertise.id + "/blockedAnswer", {
            method: "PUT", headers: {
                Authorization: "Bearer " + this.context.sessionId, "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            }
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    this.tableElement.current.loadObjects();
                } else {
                    throw Error(result.statusText);
                }
            });
    }

    shareExpertise() {
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/expertises/" + this.state.expertise.id + "/sharedExpertise", {
            method: "POST", headers: {
                Authorization: "Bearer " + this.context.sessionId, "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            },
            body: JSON.stringify({userId: this.state.expertise.user.id})
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    this.tableElement.current.loadObjects();
                    this.setState({
                        mode: MODE_DEFAULT
                    });
                } else {
                    throw Error(result.statusText);
                }
            });
    }

    deleteExpertise() {
        this.setState({
            mode: MODE_DEFAULT
        });
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/expertises/" + this.state.expertise.id + "/deletedExpertise", {
            method: "DELETE", headers: {
                Authorization: "Bearer " + this.context.sessionId, "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            }
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    this.tableElement.current.loadObjects();
                } else {
                    throw Error(result.statusText);
                }
            });
    }

}

ContainerExpertises.contextType = AppContext;

export default ContainerExpertises;
