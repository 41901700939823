import React from 'react';
import AppContext from '../../../AppContext';
import Modal from "../../../component/modal/Modal";
import List from "../../../component/view/List";

const MODE_DEFAULT = 0;
const MODE_VIEW_EXPERTISE = 1;

class ContainerSharedExpertises extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            mode: MODE_DEFAULT
        };
        this.tableElement = React.createRef();
    }

    loadSharedExpertise(sharedExpertiseId) {
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/sharedExpertises/" + sharedExpertiseId, {
            headers: {
                Authorization: "Bearer " + this.context.sessionId, "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            }
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    return result.json();
                } else {
                    throw Error(result.statusText);
                }
            })
            .then(result => {
                this.setState({
                    sharedExpertise: result
                });
            });
    }

    render() {
        return <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Udostępnione odpowiedzi</h1>
            </div>
            <List filters={[
                {
                    name: "Pytanie",
                    type: "text",
                    property: "question"
                }, {
                    name: "Odpowiedź",
                    type: "text",
                    property: "answer"
                }
            ]}
                  objectRenderer={sharedExpertise => <div className="w-100">
                      <div className="d-flex justify-content-between align-items-center">
                          <div>{sharedExpertise.question.split("\n").map(line => <p className="mb-1">{line}</p>)}</div>
                      </div>
                      <hr/>
                      {sharedExpertise.answer.split("\n").map(line => <p className="mb-1">{line}</p>)}
                      <div className="btn-group" role="group" aria-label="Akcje">
                          <button type="button" className="btn btn-primary"
                                  onClick={this.startViewSharedExpertise.bind(this, sharedExpertise.id)}>
                              Pokaż szczegóły
                          </button>
                      </div>
                  </div>}
                  objectsUrl="sharedExpertises" changesUrl="changes" ref={this.tableElement}
            />
            {this.state.mode === MODE_VIEW_EXPERTISE && <Modal
                title="Odpowiedź"
                onCancel={this.cancel.bind(this)}
                onConfirm={this.cancel.bind(this)}
                cancelTitle="Anuluj"
                confirmTitle="Zamknij">
                {this.state.sharedExpertise === null && <div className="spinner-border" role="status">
                    <span className="sr-only"/>
                </div>}
                {this.state.sharedExpertise !== null && <form>
                    <div className="mb-3">
                        <label className="form-label">Pytanie</label>
                        <p>{this.state.sharedExpertise.question}</p>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Odpowiedź</label>
                        {this.state.sharedExpertise.answer.split("\n").map((line, index) => <p key={index}>{line}</p>)}
                    </div>
                </form>}
            </Modal>}
        </main>;
    }

    shorted(value) {
        if (value) {
            const lines = value.split("\n");
            return lines.length > 1 ? lines[0] + " (...)" : lines[0];
        } else {
            return "-";
        }
    }

    startViewSharedExpertise(sharedExpertiseId) {
        this.setState({
            mode: MODE_VIEW_EXPERTISE,
            sharedExpertise: null,
            sharedExpertiseId: sharedExpertiseId
        }, () => this.loadSharedExpertise(sharedExpertiseId));
    }

    cancel() {
        this.setState({
            mode: MODE_DEFAULT
        });
    }

}

ContainerSharedExpertises.contextType = AppContext;

export default ContainerSharedExpertises;
