import React from 'react';
import AppContext from "../../AppContext";
import FilterText from "./FilterText";
import FilterIntegerRange from "./FilterIntegerRange";
import FilterEnum from "./FilterEnum";
import FilterEnums from "./FilterEnums";
import {Dropdown, DropdownButton} from "react-bootstrap";

class List extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            filters: this.props.defaultFilters ? this.props.defaultFilters : {},
            filtersVisible: false,
            sortItems: this.props.sortItems ? this.props.sortItems : [],
            sort: null,
            sortLabel: null,
            descending: null,
            objects: [],
            offset: 0,
            limit: process.env.REACT_APP_PAGE_LIMIT,
            pageFirst: null,
            pagePrevious: null,
            pageNext: null,
            pageLast: null
        };
    }

    componentDidMount() {
        if (this.props.changesUrl) {
            this.eventSource = new EventSource(process.env.REACT_APP_API_REST_URL + "/" + this.props.changesUrl + "?sessionId=" + this.context.sessionId + "&tenantId=" + process.env.REACT_APP_TENANT_ID);
            this.eventSource.onmessage = this.loadObjects.bind(this);
        }
        this.loadObjects();
    }

    loadObjects() {
        let filterQuery = "";
        if (this.state.sort) {
            filterQuery += "&sort=" + this.state.sort;
        }
        if (this.state.descending) {
            filterQuery += "&descending=true";
        }
        for (let property in this.state.filters) {
            if (Array.isArray(this.state.filters[property])) {
                for (const filter of this.state.filters[property]) {
                    filterQuery += "&" + property + "=" + encodeURIComponent(filter);
                }
            } else {
                filterQuery += "&" + property + "=" + encodeURIComponent(this.state.filters[property]);
            }
        }
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/" + this.props.objectsUrl + "?offset=" + this.state.offset + "&limit=" + this.state.limit + filterQuery, {
            headers: {
                Authorization: "Bearer " + this.context.sessionId,
                "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            }
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    const pages = {
                        first: null,
                        previous: null,
                        next: null,
                        last: null
                    };
                    const links = result.headers.get("link");
                    if (links) {
                        links.split(",").forEach(link => {
                            pages[link.match(/rel="(.*)"/)[1]] = link.match(/<(.*)>/)[1];
                        });
                    }
                    this.setState({
                        pageFirst: pages.first,
                        pagePrevious: pages.previous,
                        pageNext: pages.next,
                        pageLast: pages.last
                    });
                    return result.json();
                } else {
                    throw Error(result.statusText);
                }
            })
            .then(result => {
                this.setState({objects: result});
            });
    }

    componentWillUnmount() {
        if (this.eventSource) {
            this.eventSource.close();
        }
    }

    render() {
        return <div className="row gap-3">
            {this.props.filters && this.props.filters.length > 0 && <div className="w-100">
                {!this.state.filtersVisible &&
                    <button className="page-link d-inline-block" onClick={this.showFilters.bind(this)}>
                        <span className="sr-only">Pokaż filtry</span>
                    </button>}
                {this.state.filtersVisible &&
                    <button className="page-link d-inline-block" onClick={this.hideFilters.bind(this)}>
                        <span className="sr-only">Ukryj filtry</span>
                    </button>}
                <DropdownButton className="d-inline-block"
                                title={this.state.sortLabel ? "Sortuj " + this.state.sortLabel : "Sortuj"}>
                    {this.state.sortItems.map((sortItem, index) => <Dropdown.Item
                        key={index}
                        onClick={this.updateSort.bind(this, sortItem)}>
                        {sortItem.label}
                    </Dropdown.Item>)}
                </DropdownButton>
                {this.state.filtersVisible && this.renderFilters()}
            </div>}
            <ul className="list-group">
                {this.state.objects.map(this.renderObject.bind(this))}
            </ul>
            <nav>
                <ul className="pagination">
                    <li className={"page-item " + (this.hasFirstPage() ? "active" : "disabled")}>
                        <button className="page-link" onClick={this.goToFirstPage.bind(this)}>
                            <span aria-hidden="true">&laquo;</span>
                            <span className="sr-only">Pierwsza</span>
                        </button>
                    </li>
                    <li className={"page-item " + (this.hasPreviousPage() ? "active" : "disabled")}>
                        <button className="page-link" onClick={this.goToPreviousPage.bind(this)}>
                            <span aria-hidden="true">&lt;</span>
                            <span className="sr-only">Poprzednia</span>
                        </button>
                    </li>
                    <li className={"page-item " + (this.hasNextPage() ? "active" : "disabled")}>
                        <button className="page-link" onClick={this.goToNextPage.bind(this)}>
                            <span aria-hidden="true">&gt;</span>
                            <span className="sr-only">Następna</span>
                        </button>
                    </li>
                    <li className={"page-item " + (this.hasLastPage() ? "active" : "disabled")}>
                        <button className="page-link" onClick={this.goToLastPage.bind(this)}>
                            <span aria-hidden="true">&raquo;</span>
                            <span className="sr-only">Ostatnia</span>
                        </button>
                    </li>
                </ul>
            </nav>
        </div>;
    }

    renderFilters() {
        return <div>
            {this.props.filters.map((filter, index) => <div key={index}>
                <label>{filter.name}</label>
                {this.renderFilter(filter, index)}
            </div>)}
        </div>;
    }

    renderFilter(filter) {
        if (filter.type === "enum") {
            return <FilterEnum property={filter.property} items={filter.items} value=""
                               onChange={this.updateFilter.bind(this)}/>;
        } else if (filter.type === "enums") {
            return <FilterEnums property={filter.property} items={filter.items} value={filter.value}
                                onChange={this.updateFilter.bind(this)}/>;
        } else if (filter.type === "integerRange") {
            return <FilterIntegerRange property={filter.property} min={filter.min} value=""
                                       onChange={this.updateFilter.bind(this)}/>;
        } else if (filter.type === "text") {
            return <FilterText property={filter.property} value="" onChange={this.updateFilter.bind(this)}/>;
        } else {
            throw Error("Unsupported type: " + filter.type);
        }
    }

    updateFilter(property, value) {
        const filters = this.state.filters;
        if (value === null) {
            delete filters[property];
        } else {
            filters[property] = value;
        }
        this.setState({filters: filters}, () => this.loadObjects());
    }

    updateSort(sortItem) {
        this.setState({
            sort: sortItem.sort,
            sortLabel: sortItem.label,
            descending: sortItem.descending
        }, () => this.loadObjects());
    }

    renderObject(object, index) {
        return <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
            {this.props.objectRenderer(object)}
        </li>;
    }

    goToFirstPage() {
        this.goTo(this.state.pageFirst);
    }

    goToPreviousPage() {
        this.goTo(this.state.pagePrevious);
    }

    goToNextPage() {
        this.goTo(this.state.pageNext);
    }

    goToLastPage() {
        this.goTo(this.state.pageLast);
    }

    goTo(url) {
        const searchParams = new URL(url).searchParams;
        this.setState({
            objects: [],
            offset: searchParams.get("offset"),
            limit: searchParams.get("limit"),
            pageFirst: null,
            pagePrevious: null,
            pageNext: null,
            pageLast: null
        }, () => this.loadObjects());
    }

    hasFirstPage() {
        return this.state.pageFirst != null;
    }

    hasPreviousPage() {
        return this.state.pagePrevious != null;
    }

    hasNextPage() {
        return this.state.pageNext != null;
    }

    hasLastPage() {
        return this.state.pageLast != null;
    }

    showFilters() {
        return this.setState({
            filtersVisible: true
        });
    }

    hideFilters() {
        return this.setState({
            filtersVisible: false
        });
    }

}

List.contextType = AppContext;

export default List;
