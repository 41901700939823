import React from 'react';
import {Route} from 'react-router-dom';

import AppContext from "../../AppContext";
import SidebarMenuListItem from "./container/SidebarMenuListItem";
import ContainerHome from "./container/ContainerHome";
import ContainerUsers from "./container/ContainerUsers";
import ContainerExpertises from "./container/ContainerExpertises";
import ContainerConsultations from "./container/ContainerConsultations";
import ContainerNotificationTemplates from "./container/ContainerNotificationTemplates";
import ContainerExpertWaitingConsultations from "./container/ContainerExpertWaitingConsultations";
import ContainerSharedExpertises from "./container/ContainerSharedExpertises";

class Container extends React.Component {

    render() {
        return <div className="container-fluid">
            <div className="row">
                <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
                    <div className="position-sticky pt-3">
                        <ul className="nav flex-column">
                            {this.context.currentUser.roles.includes("ADMINISTRATOR")
                                && <SidebarMenuListItem icon="notificationTemplates" label="Szablony powiadomień"
                                                        to="/notificationTemplates"/>}
                            {this.context.currentUser.roles.includes("ADMINISTRATOR")
                                && <SidebarMenuListItem icon="users" label="Użytkownicy" to="/users"/>}
                            {this.context.currentUser.roles.includes("EXPERT")
                                && <SidebarMenuListItem icon="expertises" label="Pytania" to="/expertises"/>}
                            {this.context.currentUser.roles.includes("EXPERT")
                                && <SidebarMenuListItem icon="expertises" label="Oczekujące konsultacje"
                                                        to="/expertWaitingConsultations"/>}
                            {this.context.currentUser.roles.includes("CONSULTANT")
                                &&
                                <SidebarMenuListItem icon="consultations" label="Konsultacje" to="/consultations"/>}
                            {this.context.currentUser.roles.includes("CONSULTANT")
                                &&
                                <SidebarMenuListItem icon="sharedExpertises" label="Udostępnione odpowiedzi"
                                                     to="/sharedExpertises"/>}
                        </ul>
                    </div>
                </nav>
                <Route exact path="/" component={ContainerHome}/>
                <Route path="/notificationTemplates" component={ContainerNotificationTemplates}/>
                <Route path="/users" component={ContainerUsers}/>
                <Route path="/expertises" component={ContainerExpertises}/>
                <Route path="/expertWaitingConsultations" component={ContainerExpertWaitingConsultations}/>
                <Route path="/consultations" component={ContainerConsultations}/>
                <Route path="/sharedExpertises" component={ContainerSharedExpertises}/>
            </div>
        </div>;
    }

}

Container.contextType = AppContext;

export default Container;
