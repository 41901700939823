import React from 'react';
import {Link} from "react-router-dom";

class SidebarMenuListItem extends React.Component {

    render() {
        return <li className="nav-item">
            <Link className="nav-link active" aria-current="page" to={this.props.to}>
                <span data-feather={this.props.icon}></span>
                {this.props.label}
            </Link>
        </li>;
    }

}

export default SidebarMenuListItem;
