import React from 'react';
import AppContext from '../../../AppContext';
import Modal from "../../../component/modal/Modal";
import InputTextarea from "../../../component/form/InputTextarea";
import InputText from "../../../component/form/InputText";
import List from "../../../component/view/List";

const MODE_DEFAULT = 0;
const MODE_DO_CONSULTATION = 1;
const MODE_CANCEL_CONSULTATION = 2;

class ContainerConsultations extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            mode: MODE_DEFAULT,
            consultations: [],
            offset: 0,
            limit: process.env.REACT_APP_PAGE_LIMIT,
            pageFirst: null,
            pagePrevious: null,
            pageNext: null,
            pageLast: null
        };
        this.tableElement = React.createRef();
    }

    render() {
        return <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Pytania</h1>
            </div>
            <List filters={[
                {
                    name: "Status",
                    type: "enums",
                    property: "status",
                    items: [
                        {
                            value: "WAITING",
                            label: "Wymaga odpowiedzi"
                        }, {
                            value: "CANCELLED",
                            label: "Anulowano"
                        }, {
                            value: "DONE",
                            label: "Udzielono odpowiedzi"
                        }
                    ],
                    value: ["WAITING", "WAITING_FOR_CONSULTATION"]
                }
            ]} defaultFilters={{status: ["WAITING"]}}
                  sortItems={[
                      {
                          sort: "createdTime",
                          label: "rosnąco wg daty",
                          descending: false
                      }, {
                          sort: "createdTime",
                          label: "malejąco wg daty",
                          descending: true
                      }
                  ]}
                  objectRenderer={consultation => <div className="w-100">
                      <div className="d-flex justify-content-between align-items-center">
                          <div>{consultation.question.split("\n").map(line => <p className="mb-1">{line}</p>)}</div>
                      </div>
                      {consultation.answer && <hr/>}
                      {consultation.answer && consultation.answer.split("\n").map(line => <p
                          className="mb-1">{line}</p>)}
                      <p className="mb-1">
                          {this.getStatuses(consultation)}
                      </p>
                      {!consultation.cancelled && !consultation.done &&
                          <div className="btn-group" role="group" aria-label="Akcje">
                              {!consultation.cancelled && !consultation.done &&
                                  <button type="button" className="btn btn-primary"
                                          onClick={this.startDoConsultation.bind(this, consultation)}>
                                      {this.context.messages["consultations.actions.answer.name"]}
                                  </button>}
                              {!consultation.cancelled && !consultation.done &&
                                  <button type="button" className="btn btn-secondary"
                                          onClick={this.startCancelConsultation.bind(this, consultation)}>
                                      Anuluj
                                  </button>}
                          </div>}
                  </div>}
                  objectsUrl="consultations" changesUrl="changes" ref={this.tableElement}
            />
            {this.state.mode === MODE_DO_CONSULTATION && <Modal
                title={this.context.messages["consultations.actions.answer.name"]}
                actions={[
                    {
                        className: "secondary",
                        onClick: this.cancel.bind(this),
                        title: "Anuluj"
                    }, {
                        className: "primary",
                        onClick: this.sketchConsultation.bind(this),
                        title: "Zapisz szkic"
                    }, {
                        className: "primary",
                        onClick: this.doConsultation.bind(this),
                        title: this.context.messages["consultations.actions.answer.confirm"]
                    }
                ]}
                size="modal-lg">
                <form>
                    <div className="mb-3">
                        <InputTextarea label="Odpowiedź" name="consultation.answer" parent={this} rows={10}/>
                    </div>
                </form>
            </Modal>}
            {this.state.mode === MODE_CANCEL_CONSULTATION && <Modal
                title="Anuluj konsultację"
                onCancel={this.cancel.bind(this)}
                onConfirm={this.cancelConsultation.bind(this)}
                cancelTitle="Nie"
                confirmTitle="Tak">
                <form>
                    <p>Czy na peno chcesz anulować konsultację "{this.state.consultation.question}"?</p>
                    <InputText label="Powód" name="consultation.reason" parent={this}/>
                </form>
            </Modal>}
        </main>;
    }

    getStatuses(consultation) {
        const statuses = [];
        if (!consultation.cancelled && !consultation.done) {
            statuses.push(<span className="badge bg-danger">Wymaga odpowiedzi</span>);
        }
        if (consultation.cancelled) {
            statuses.push(<span className="badge bg-danger">Anulowano</span>);
        }
        if (!consultation.done && consultation.answer !== null) {
            statuses.push(<span className="badge bg-info">Naszkicowano odpowiedź</span>);
        }
        if (consultation.done) {
            statuses.push(<span className="badge bg-success">Udzielono odpowiedzi</span>);
        }
        return <h6>{statuses}</h6>;
    }

    startDoConsultation(consultation) {
        this.setState(state => ({
            mode: MODE_DO_CONSULTATION,
            consultation: {
                id: consultation.id,
                question: consultation.question,
                answer: consultation.answer
            },
            consultations: state.consultations,
            offset: state.offset,
            limit: state.limit,
            pageFirst: state.pageFirst,
            pagePrevious: state.pagePrevious,
            pageNext: state.pageNext,
            pageLast: state.pageLast
        }));
    }

    startCancelConsultation(consultation) {
        this.setState({
            mode: MODE_CANCEL_CONSULTATION,
            consultation: {
                id: consultation.id,
                question: consultation.question
            }
        });
    }

    cancel() {
        this.setState(state => ({
            mode: MODE_DEFAULT,
            consultations: state.consultations,
            offset: state.offset,
            limit: state.limit,
            pageFirst: state.pageFirst,
            pagePrevious: state.pagePrevious,
            pageNext: state.pageNext,
            pageLast: state.pageLast
        }));
    }

    sketchConsultation() {
        this.setState(state => ({
            mode: MODE_DEFAULT,
            consultations: state.consultations,
            offset: state.offset,
            limit: state.limit,
            pageFirst: state.pageFirst,
            pagePrevious: state.pagePrevious,
            pageNext: state.pageNext,
            pageLast: state.pageLast
        }));
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/consultations/" + this.state.consultation.id + "/consultationSketch", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + this.context.sessionId,
                "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            },
            body: JSON.stringify(this.state.consultation)
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    this.tableElement.current.loadObjects();
                } else {
                    throw Error(result.statusText);
                }
            });
    }

    doConsultation() {
        this.setState(state => ({
            mode: MODE_DEFAULT,
            consultations: state.consultations,
            offset: state.offset,
            limit: state.limit,
            pageFirst: state.pageFirst,
            pagePrevious: state.pagePrevious,
            pageNext: state.pageNext,
            pageLast: state.pageLast
        }));
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/consultations/" + this.state.consultation.id + "/consultationAnswer", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + this.context.sessionId,
                "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            },
            body: JSON.stringify(this.state.consultation)
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    this.tableElement.current.loadObjects();
                } else {
                    throw Error(result.statusText);
                }
            });
    }

    cancelConsultation() {
        this.setState({mode: MODE_DEFAULT});
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/consultations/" + this.state.consultation.id + "/consultationCancellation", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + this.context.sessionId,
                "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            },
            body: JSON.stringify(this.state.consultation)
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    this.tableElement.current.loadObjects();
                } else {
                    throw Error(result.statusText);
                }
            });
    }

    setAnswer(event) {
        let state = this.state;
        state.consultation.answer = event.target.value;
        this.setState(state);
    }

}

ContainerConsultations.contextType = AppContext;

export default ContainerConsultations;
