import React from 'react';

import AppContext from '../../AppContext';
import {Link} from "react-router-dom";
import {Nav, NavDropdown} from "react-bootstrap";
import Modal from "../../component/modal/Modal";
import InputPassword from "../../component/form/InputPassword";

const MODE_DEFAULT = 0;
const MODE_CHANGE_PASSWORD = 1;

class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: MODE_DEFAULT
        };
    }

    render() {
        return <header className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
            <Link className="navbar-brand col-md-3 col-lg-2 me-0 px-3" to="/">Poradnia</Link>
            <button className="navbar-toggler position-absolute d-md-none collapsed" type="button"
                    data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu"
                    aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <Nav>
                <NavDropdown title={this.context.displayName}>
                    <NavDropdown.Item onClick={this.startChangePassword.bind(this)}>
                        {this.context.messages["users.actions.changePassword.name"]}
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={this.logout.bind(this)}>
                        {this.context.messages["users.actions.logOut.name"]}
                    </NavDropdown.Item>
                </NavDropdown>
            </Nav>
            {this.state.mode === MODE_CHANGE_PASSWORD && <Modal
                title={this.context.messages["users.actions.changePassword.name"]}
                onCancel={this.cancel.bind(this)}
                onConfirm={this.changePassword.bind(this)}
                cancelTitle="Anuluj"
                confirmTitle={this.context.messages["users.actions.changePassword.name"]}>
                <form>
                    <InputPassword label="Hasło" name="user.password" parent={this}/>
                    <InputPassword label="Hasło powtórzone" name="user.passwordRepeat" parent={this}/>
                </form>
            </Modal>}
        </header>;
    }

    cancel() {
        this.setState(state => ({
            mode: MODE_DEFAULT,
            user: null
        }));
    }

    startChangePassword() {
        this.setState({
            mode: MODE_CHANGE_PASSWORD,
            user: {
                password: "",
                passwordRepeat: ""
            }
        });
    }

    changePassword() {
        fetch(new Request(process.env.REACT_APP_API_REST_URL + "/users/" + this.context.userId + "/password", {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + this.context.sessionId,
                "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            },
            body: JSON.stringify(this.state.user)
        }))
            .then(result => {
                if (result.ok) {
                    this.setState({
                        mode: MODE_DEFAULT
                    });
                } else {
                    throw Error(result.statusText);
                }
            });
    }

    logout() {
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/sessions/" + this.context.sessionId, {
            headers: {
                Authorization: "Bearer " + this.context.sessionId
            },
            method: "DELETE"
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    this.props.onLogoutSuccess();
                } else {
                    throw Error(result.statusText);
                }
            });
    }

}

Header.contextType = AppContext;

export default Header;
