import React from 'react';
import Select from "react-select";

class FilterEnums extends React.Component {

    constructor(props, context) {
        super(props, context);
        const selectedItems = [];
        for (const item of this.props.items) {
            if (Array.isArray(this.props.value) && this.props.value.includes(item.value)) {
                selectedItems.push(item);
            }
        }
        this.state = {
            selectedItems: selectedItems,
            value: this.props.value
        };
        this.onChange(selectedItems);
    }

    render() {
        return <div className="row">
            <div className="col-auto">
                <Select className="d-inline w-auto" isMulti={true} options={this.props.items}
                        styles={{
                            menu: (css) => ({
                                ...css,
                                width: "auto"
                            })
                        }}
                        value={this.state.selectedItems}
                        onChange={this.onChange.bind(this)}
                        placeholder="" noOptionsMessage=""/>
            </div>
            <div className="col-auto">{this.props.children}</div>
        </div>;
    }

    onChange(selectedItems) {
        const values = selectedItems.map(item => item.value);
        this.setState({
            selectedItems: selectedItems,
            value: values
        });
        this.props.onChange(this.props.property, values);
    }

}

export default FilterEnums;
