import React from 'react';

class FilterEnum extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            value: this.props.value
        };
    }

    render() {
        return <select className="form-select"
                       onChange={this.onChange.bind(this)}
                       value={this.state.value}>
            <option value={null}></option>
            {
                this.props.items.map(item => <option key={item.value} value={item.value}>
                    {item.label}
                </option>)
            }
        </select>;
    }

    onChange(event) {
        let value = event.target.value;
        if (value === "") {
            value = null;
        }
        this.setState({value: value});
        this.props.onChange(this.props.property, value);
    }

}

export default FilterEnum;
