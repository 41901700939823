import React from 'react';

import './App.css';
import AppContext from './AppContext';
import SignIn from "./view/SignIn";
import Dashboard from "./view/Dashboard";
import Footer from "./view/Footer";
import Inactive from "./view/Inactive";

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            active: true,
            context: {
                email: null,
                userId: null,
                sessionId: null,
                messages: {}
            }
        };
    }

    componentDidMount() {
        fetch(new Request(process.env.REACT_APP_API_REST_URL + "/messages", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            }
        }))
            .then(result => {
                if (result.ok) {
                    return result.json();
                } else {
                    throw Error(result.statusText);
                }
            })
            .then(messages => {
                document.title = messages["app.name"];
                const context = this.state.context;
                context.messages = messages;
                this.setState({context: context});
            });
        fetch(new Request(process.env.REACT_APP_API_REST_URL + "/diagnostics/status", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            }
        }))
            .then(result => {
                if (result.ok) {
                    return result.json();
                } else {
                    throw new Error(result.statusText);
                }
            })
            .then(result => {
                this.setState({active: result.ok === true});
            })
            .catch(reason => this.setState({active: false}));
    }

    render() {
        return <AppContext.Provider value={this.state.context}>
            {this.state.active && this.state.context.email === null
                && <SignIn onLoginSuccess={this.login.bind(this)}/>}
            {this.state.active && this.state.context.email !== null
                && <Dashboard onLogoutSuccess={this.logout.bind(this)}/>}
            {!this.state.active && <Inactive/>}
            <Footer/>
        </AppContext.Provider>;
    }

    login(sessionId) {
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/users/currentUser", {
            headers: {
                Authorization: "Bearer " + sessionId,
                "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            }
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    return result.json();
                } else {
                    throw Error(result.statusText);
                }
            })
            .then(result => {
                const context = this.state.context;
                context.displayName = result.displayName;
                context.email = result.email;
                context.userId = result.id;
                context.sessionId = sessionId;
                context.currentUser = result;
                this.setState({context: context});
            });
    }

    logout() {
        const state = this.state;
        state.context.email = null;
        state.context.sessionId = null;
        this.setState(state);
    }

}

export default App;
