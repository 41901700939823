import React from 'react';

class FilterIntegerRange extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            valueMin: this.props.valueMin,
            valueMax: this.props.valueMax
        };
    }

    render() {
        return <div className="row">
            <div className="col-auto"><label className="form-label">od</label></div>
            <div className="col">
                <input type="number" className="form-control"
                       value={this.state.valueMin}
                       onChange={this.onChangeMin.bind(this)}/>
            </div>
            <div className="col-auto"><label className="form-label">do</label></div>
            <div className="col">
                <input type="number" className="form-control"
                       value={this.state.valueMax}
                       onChange={this.onChangeMax.bind(this)}/>
            </div>
        </div>;
    }

    onChangeMin(event) {
        let value = parseInt(event.target.value);
        if (value < this.props.min) {
            value = this.props.min;
        }
        if (isNaN(value)) {
            value = null;
        }
        this.setState({valueMin: value});
        this.props.onChange(this.props.property + "Min", value);
    }

    onChangeMax(event) {
        let value = parseInt(event.target.value);
        const min = Math.max(this.props.min, this.state.valueMin);
        if (value < min) {
            value = min;
        }
        if (isNaN(value)) {
            value = null;
        }
        this.setState({valueMax: value});
        this.props.onChange(this.props.property + "Max", value);
    }

}

export default FilterIntegerRange;
