import React from 'react';
import AppContext from '../../../AppContext';
import Modal from "../../../component/modal/Modal";
import Table from "../../../component/view/Table";
import InputText from "../../../component/form/InputText";

const MODE_DEFAULT = 0;
const MODE_REMIND_EXPERTISE_CONSULTATION = 1;
const MODE_VIEW_EXPERTISE_CONSULTATION = 2;
const MODE_CANCEL_EXPERTISE_CONSULTATION = 3;

class ContainerExpertWaitingConsultations extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            mode: MODE_DEFAULT,
            consultations: [],
            selectedConsultations: [],
            offset: 0,
            limit: process.env.REACT_APP_PAGE_LIMIT,
            pageFirst: null,
            pagePrevious: null,
            pageNext: null,
            pageLast: null
        };
        this.tableElement = React.createRef();
    }

    render() {
        return <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Oczekujące konsultacje</h1>
            </div>
            <div className="btn-group" role="group" aria-label="Akcje">
                <button type="button" className="btn btn-primary"
                        onClick={this.startRemindSelectedConsultations.bind(this)}>
                    Przypomnij o wysłanych konsultacjach
                </button>
            </div>
            <Table columns={[
                {
                    label: "",
                    renderValue: consultation => <input className="form-check-input" type="checkbox"
                                                        checked={this.state.selectedConsultations.includes(consultation)}
                                                        onChange={this.toggleSelectedConsultation.bind(this, consultation)}/>
                }, {
                    label: "Konsultant",
                    renderValue: consultation => consultation.consultantDisplayName
                }, {
                    label: "Pytanie",
                    renderValue: consultation => consultation.question
                }, {
                    label: "Wysłane do konsultacji",
                    renderValue: consultation => new Date(consultation.started).toLocaleString("pl")
                }, {
                    label: "",
                    renderValue: consultation => <div className="btn-group" role="group" aria-label="Akcje">
                        <button type="button" className="btn btn-primary"
                                onClick={this.startRemindConsultations.bind(this, [consultation])}>
                            Przypomnij o wysłanej konsultacji
                        </button>
                        <button type="button" className="btn btn-primary"
                                onClick={this.startCancelConsultation.bind(this, consultation)}>
                            Anuluj konsultację
                        </button>
                        <button type="button" className="btn btn-primary"
                                onClick={this.startViewConsultation.bind(this, consultation.id)}>
                            Pokaż szczegóły
                        </button>
                    </div>
                }
            ]} objectsUrl="expertises/waitingConsultations" changesUrl="changes" ref={this.tableElement}/>
            {this.state.mode === MODE_REMIND_EXPERTISE_CONSULTATION && <Modal
                title="Przypomnij o wysłanej konsultacji"
                onCancel={this.cancel.bind(this)}
                onConfirm={this.remindConsultations.bind(this)}
                cancelTitle="Nie"
                confirmTitle="Tak">
                <form>
                    <p>Czy na peno chcesz wysłać przypomnienie dla {this.state.remindConsultations
                        .map(consultation => consultation.consultantDisplayName)
                        .join(", ")}?</p>
                </form>
            </Modal>}
            {this.state.mode === MODE_VIEW_EXPERTISE_CONSULTATION && <Modal
                title="Oczekująca konsultacja"
                onCancel={this.cancel.bind(this)}
                onConfirm={this.cancel.bind(this)}
                cancelTitle="Anuluj"
                confirmTitle="Zamknij">
                {this.state.consultation === null && <div className="spinner-border" role="status">
                    <span className="sr-only"/>
                </div>}
                {this.state.consultation !== null && <form>
                    <div className="mb-3">
                        <label className="form-label">Pytanie</label>
                        <p>{this.state.consultation.consultantDisplayName}</p>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Pytanie</label>
                        <p>{this.state.consultation.question}</p>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Wysłane do konsultacji</label>
                        <p>{new Date(this.state.consultation.started).toLocaleString("pl")}</p>
                    </div>
                </form>}
            </Modal>}
            {this.state.mode === MODE_CANCEL_EXPERTISE_CONSULTATION && <Modal
                title="Anuluj konsultację"
                onCancel={this.cancel.bind(this)}
                onConfirm={this.cancelConsultation.bind(this)}
                cancelTitle="Nie"
                confirmTitle="Tak">
                <form>
                    <p>Czy na peno chcesz anulować konsultację "{this.state.cancelledConsultation.question}"?</p>
                    <InputText label="Powód" name="cancelledConsultation.reason" parent={this}/>
                </form>
            </Modal>}
        </main>;
    }

    startViewConsultation(consultationId) {
        this.setState({
            mode: MODE_VIEW_EXPERTISE_CONSULTATION,
            consultation: null
        }, () => this.loadConsultation(consultationId));
    }

    loadConsultation(consultationId) {
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/expertises/consultations/" + consultationId, {
            headers: {
                Authorization: "Bearer " + this.context.sessionId, "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            }
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    return result.json();
                } else {
                    throw Error(result.statusText);
                }
            })
            .then(result => {
                this.setState({
                    consultation: result
                });
            });
    }

    toggleSelectedConsultation(consultation) {
        const selectedConsultations = this.state.selectedConsultations;
        if (selectedConsultations.includes(consultation)) {
            selectedConsultations.splice(selectedConsultations.indexOf(consultation), 1);
        } else {
            selectedConsultations.push(consultation);
        }
        this.setState({selectedConsultations: selectedConsultations});
    }

    startRemindSelectedConsultations() {
        this.startRemindConsultations(this.state.selectedConsultations);
    }

    startRemindConsultations(consultations) {
        this.setState({
            mode: MODE_REMIND_EXPERTISE_CONSULTATION,
            remindConsultations: consultations
        });
    }

    startCancelConsultation(consultation) {
        this.setState({
            mode: MODE_CANCEL_EXPERTISE_CONSULTATION,
            cancelledConsultation: consultation
        });
    }

    cancel() {
        this.setState({
            mode: MODE_DEFAULT
        });
    }

    remindConsultations() {
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/expertises/waitingConsultations/reminders", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + this.context.sessionId, "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            },
            body: JSON.stringify({consultationIds: this.state.remindConsultations.map(consultation => consultation.id)})
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    this.setState({
                        mode: MODE_DEFAULT,
                        selectedConsultations: []
                    });
                } else {
                    throw Error(result.statusText);
                }
            });
    }

    cancelConsultation() {
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/consultations/" + this.state.cancelledConsultation.id + "/consultationCancellation", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + this.context.sessionId, "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            },
            body: JSON.stringify({reason: this.state.cancelledConsultation.reason})
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    this.setState({
                        mode: MODE_DEFAULT,
                    }, () => this.tableElement.current.loadObjects());
                } else {
                    throw Error(result.statusText);
                }
            });
    }

}

ContainerExpertWaitingConsultations.contextType = AppContext;

export default ContainerExpertWaitingConsultations;
