import React from 'react';
import AppContext from "../../AppContext";

class InputTextareaWithCopyableFragments extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            fragments: [],
            selectedFragment: null
        };
        this.textareaElement = React.createRef();
    }

    componentDidMount() {
        this.loadFragments();
    }

    loadFragments() {
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/" + this.props.fragmentsUrl, {
            headers: {
                Authorization: "Bearer " + this.context.sessionId,
                "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            }
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    return result.json();
                } else {
                    throw Error(result.statusText);
                }
            })
            .then(result => {
                this.setState({fragments: result});
            });
    }

    render() {
        return <div className="form-group">
            {typeof this.props.label === "string" && <label className="form-label">{this.props.label}</label>}
            <div className="row">
                <div className="col">
                    <div className="row">
                        <div className="col">
                            <select className="form-select"
                                    onChange={this.onChangeFragment.bind(this)}
                                    value={this.state.selectedFragment !== null ? this.props.fragmentIdExtractor(this.state.selectedFragment) : ""}>
                                <option value={null}></option>
                                {
                                    this.state.fragments.map(fragment => <option
                                        key={this.props.fragmentIdExtractor(fragment)}
                                        value={this.props.fragmentIdExtractor(fragment)}>
                                        {this.props.fragmentTitleFormatter(fragment)}
                                    </option>)
                                }
                            </select>
                        </div>
                        <div className="col">
                            <button type="button" className="btn btn-primary"
                                    onClick={this.copyFragment.bind(this)}
                                    disabled={this.state.selectedFragment === null}>
                                {this.props.copyFragmentTitle}
                            </button>
                        </div>
                    </div>
                    <div className="row">
                        {this.props.fragmentContentFormatter(this.state.selectedFragment).split("\n")
                            .map((line, index) => <p key={index}>{line}</p>)}
                    </div>
                </div>
                <div className="col">
                        <textarea className={this.getInputClassName()}
                                  value={this.getValue()}
                                  onChange={this.onChange.bind(this)}
                                  onKeyPress={this.onKeyPress.bind(this)}
                                  disabled={this.props.disabled}
                                  ref={this.textareaElement}
                                  rows={this.props.rows}/>
                </div>
            </div>
            {Array.isArray(this.props.errors) && this.props.errors.map(error => <div key={error}
                                                                                     className="alert alert-danger"
                                                                                     role="alert">
                {error}
            </div>)}
        </div>;
    }

    onChangeFragment(event) {
        let value = event.target.value;
        for (const fragment of this.state.fragments) {
            if (this.props.fragmentIdExtractor(fragment) === value) {
                this.setState({selectedFragment: fragment});
            }
        }
    }

    copyFragment() {
        const caretPosition = this.getCaretPosition();
        const fragmentContent = this.props.fragmentContentExtractor(this.state.selectedFragment);
        this.setValue(this.getValue().substring(0, caretPosition.start) + fragmentContent + this.getValue().substring(caretPosition.end));
    }

    getCaretPosition() {
        if (this.textareaElement.current.selectionStart || this.textareaElement.current.selectionStart === "0") {
            return {
                start: this.textareaElement.current.selectionStart,
                end: this.textareaElement.current.selectionEnd
            };
        } else {
            return {
                start: 0,
                end: 0
            };
        }
    }

    getInputClassName() {
        const classNames = ["form-control"];
        if (this.props.errors && this.props.errors.length > 0) {
            classNames.push("is-invalid");
        }
        return classNames.join(" ");
    }

    getValue() {
        if (this.props.name && this.props.parent) {
            const propertyNames = this.props.name.split(".");
            let state = this.props.parent.state;
            let property = state;
            for (let i = 0; i < propertyNames.length; ++i) {
                property = property[propertyNames[i]];
            }
            return typeof property === "string" ? property : "";
        } else {
            return typeof this.props.value === "string" ? this.props.value : "";
        }
    }

    onChange(event) {
        this.setValue(event.target.value);
    }

    setValue(value) {
        if (this.props.name && this.props.parent) {
            const propertyNames = this.props.name.split(".");
            let state = this.props.parent.state;
            let property = state;
            for (let i = 0; i < propertyNames.length - 1; ++i) {
                property = property[propertyNames[i]];
            }
            property[propertyNames[propertyNames.length - 1]] = value;
            this.props.parent.setState(state);
        }
        if (typeof this.props.onChange === "function") {
            this.props.onChange(value);
        }
    }

    onKeyPress(event) {
        if (event.key === "Enter" && typeof this.props.onEnter === "function") {
            this.props.onEnter();
        }
    }

}

InputTextareaWithCopyableFragments.contextType = AppContext;

export default InputTextareaWithCopyableFragments;
