import React from 'react';

class Modal extends React.Component {

    componentDidMount() {
        document.body.classList.add("modal-open");
    }

    componentWillUnmount() {
        document.body.classList.remove("modal-open");
    }

    render() {
        return <div className="modal d-block" tabIndex="-1">
            <div className={this.getModalDialogClass()}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{this.props.title}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal"
                                aria-label="Close" onClick={this.cancel.bind(this)}></button>
                    </div>
                    <div className="modal-body">
                        {this.props.children}
                    </div>
                    <div className="modal-footer">
                        {this.props.onCancel &&
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                                    onClick={this.cancel.bind(this)}>
                                {this.props.cancelTitle}
                            </button>}
                        {this.props.onConfirm && <button type="button" className="btn btn-primary"
                                                         onClick={this.confirm.bind(this)}>
                            {this.props.confirmTitle}
                        </button>}
                        {this.props.actions && this.props.actions.map((action, actionId) => <button key={actionId}
                                                                                                    type="button"
                                                                                                    className={this.getActionClassNames(action.className)}
                                                                                                    onClick={action.onClick.bind(this)}>
                            {action.title}
                        </button>)}
                    </div>
                </div>
            </div>
        </div>;
    }

    getActionClassNames(className) {
        const classNames = ["btn"];
        if (typeof className === "string") {
            classNames.push("btn-" + className);
        }
        return classNames.join(" ");
    }

    cancel() {
        this.props.onCancel();
    }

    confirm() {
        this.props.onConfirm();
    }

    getModalDialogClass() {
        const classNames = ["modal-dialog"];
        if (typeof this.props.size === "string") {
            classNames.push(this.props.size);
        } else if (Array.isArray(this.props.size)) {
            this.props.size.forEach(size => classNames.push(size));
        }
        return classNames.join(" ");
    }

}

export default Modal;
