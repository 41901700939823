import React from 'react';
import AppContext from "../AppContext";

class Footer extends React.Component {

    render() {
        return <footer className="footer mt-auto">
            <div className="container">
                <span className="text-muted"
                      dangerouslySetInnerHTML={{__html: this.context.messages["app.copyright"]}}></span>
            < /div>
        </footer>;
    }

}

Footer.contextType = AppContext;

export default Footer;
