import React from 'react';

class InputPassword extends React.Component {

    render() {
        return <div className="form-group">
            <label className="form-label">{this.props.label}</label>
            <input type="password" className="form-control"
                   value={this.getValue()}
                   onChange={this.onChange.bind(this)}
                   onKeyPress={this.onKeyPress.bind(this)}
                   disabled={this.props.disabled}/>
        </div>;
    }

    getValue() {
        if (this.props.name && this.props.parent) {
            const propertyNames = this.props.name.split(".");
            let state = this.props.parent.state;
            let property = state;
            for (let i = 0; i < propertyNames.length; ++i) {
                property = property[propertyNames[i]];
            }
            return property;
        } else {
            return this.props.value;
        }
    }

    onChange(event) {
        const value = event.target.value;
        if (this.props.name && this.props.parent) {
            const propertyNames = this.props.name.split(".");
            let state = this.props.parent.state;
            let property = state;
            for (let i = 0; i < propertyNames.length - 1; ++i) {
                property = property[propertyNames[i]];
            }
            property[propertyNames[propertyNames.length - 1]] = value;
            this.props.parent.setState(state);
        }
        if (typeof this.props.onChange === "function") {
            this.props.onChange(value);
        }
    }

    onKeyPress(event) {
        if (event.key === "Enter" && typeof this.props.onEnter === "function") {
            this.props.onEnter();
        }
    }

}

export default InputPassword;
