import React from 'react';
import AppContext from '../../../AppContext';
import Modal from "../../../component/modal/Modal";
import InputText from "../../../component/form/InputText";
import Table from "../../../component/view/Table";
import InputTextarea from "../../../component/form/InputTextarea";

const MODE_DEFAULT = 0;
const MODE_CREATE = 1;
const MODE_CHANGE_DISPLAY_NAME = 2;
const MODE_CHANGE_EMAIL = 3;
const MODE_CHANGE_PASSWORD = 4;
const MODE_DELETE = 5;
const MODE_ADD_ROLE = 6;
const MODE_REMOVE_ROLE = 7;
const MODE_ACTIVATE = 8;
const MODE_DEACTIVATE = 9;

const ROLE_NAMES = {
    ADMINISTRATOR: "Administrator",
    CONSULTANT: "Konsultant",
    EXPERT: "Ekspert"
};

class ContainerUsers extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            mode: MODE_DEFAULT,
            users: [],
            offset: 0,
            limit: process.env.REACT_APP_PAGE_LIMIT,
            pageFirst: null,
            pagePrevious: null,
            pageNext: null,
            pageLast: null
        };
        this.tableElement = React.createRef();
    }

    render() {
        return <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div
                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Użytkownicy</h1>
            </div>
            <Table
                columns={[
                    {
                        label: "Wyświetlana nazwa",
                        renderValue: user => <div>{user.displayName} <i className="bi bi-pen"
                                                                        onClick={this.startChangeDisplayName.bind(this, user)}/>
                        </div>
                    }, {
                        label: "Email",
                        renderValue: user => <div>{user.email} <i className="bi bi-pen"
                                                                  onClick={this.startChangeEmail.bind(this, user)}/>
                        </div>
                    }, {
                        label: "Role",
                        renderValue: user => this.renderRoles(user)
                    }, {
                        label: "Aktywny",
                        filter: {
                            type: "enum",
                            property: "active",
                            items: [
                                {
                                    value: "true",
                                    label: "Aktywny"
                                }, {
                                    value: "false",
                                    label: "Nieaktywny"
                                }
                            ],
                            value: "true"
                        },
                        renderValue: user => <input className="form-check-input" type="checkbox" checked={user.active}
                                                    readOnly={true}/>
                    }, {
                        label: "Komentarz",
                        renderValue: user => user.comment
                    }, {
                        label: "",
                        renderValue: user => <div className="btn-group" role="group" aria-label="Akcje">
                            <button type="button" className="btn btn-primary"
                                    onClick={this.startChangePassword.bind(this, user)}>
                                Zmień hasło
                            </button>
                            {!user.active &&
                                <button type="button" className="btn btn-primary"
                                        onClick={this.startActivateUser.bind(this, user)}>
                                    Aktywuj
                                </button>}
                            {user.active &&
                                <button type="button" className="btn btn-primary"
                                        onClick={this.startDeactivateUser.bind(this, user)}>
                                    Deaktywuj
                                </button>}
                            {user.active &&
                                <button type="button" className="btn btn-danger"
                                        onClick={this.startDeleteUser.bind(this, user)}>
                                    Usuń
                                </button>}
                        </div>
                    }
                ]} defaultFilters={{active: true}} objectsUrl={"users"} changesUrl="changes" ref={this.tableElement}/>
            <button type="button" className="btn btn-primary" onClick={this.startCreateUser.bind(this)}>
                Dodaj użytkownika
            </button>
            {this.state.mode === MODE_CREATE && <Modal
                title="Utwórz użytkownika"
                onCancel={this.cancel.bind(this)}
                onConfirm={this.createUser.bind(this)}
                cancelTitle="Anuluj"
                confirmTitle="Zapisz">
                <form>
                    <InputText label="Wyświetlana nazwa" name="user.displayName" parent={this}/>
                    <InputText label="Email" name="user.email" parent={this}/>
                    <div className="mb-3">
                        <label className="form-label">Hasło</label>
                        <input type="password" className="form-control"
                               value={this.state.user.password}
                               onChange={this.setUserPassword.bind(this)}/>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Hasło powtórzone</label>
                        <input type="password" className="form-control"
                               value={this.state.user.passwordRepeat}
                               onChange={this.setUserPasswordRepeat.bind(this)}/>
                    </div>
                    <div className="mb-3 form-check">
                        <input type="checkbox" className="form-check-input"
                               value="ADMINISTRATOR"
                               checked={this.hasRole("ADMINISTRATOR")}
                               onChange={this.setUserRole.bind(this)}/>
                        <label className="form-check-label">Administrator</label>
                    </div>
                    <div className="mb-3 form-check">
                        <input type="checkbox" className="form-check-input"
                               value="CONSULTANT"
                               checked={this.hasRole("CONSULTANT")}
                               onChange={this.setUserRole.bind(this)}/>
                        <label className="form-check-label">Konsultant</label>
                    </div>
                    <div className="mb-3 form-check">
                        <input type="checkbox" className="form-check-input"
                               value="EXPERT"
                               checked={this.hasRole("EXPERT")}
                               onChange={this.setUserRole.bind(this)}/>
                        <label className="form-check-label">Ekspert</label>
                    </div>
                    <InputTextarea label="Komentarz" name="user.comment" parent={this}/>
                </form>
            </Modal>}
            {this.state.mode === MODE_CHANGE_DISPLAY_NAME && <Modal
                title="Zmień wyświetlaną nazwę"
                onCancel={this.cancel.bind(this)}
                onConfirm={this.changeDisplayName.bind(this)}
                cancelTitle="Anuluj"
                confirmTitle="Zmień wyświetlaną nazwę">
                <form>
                    <InputText label="Wyświetlana nazwa" name="user.displayName" parent={this}/>
                </form>
            </Modal>}
            {this.state.mode === MODE_CHANGE_EMAIL && <Modal
                title="Zmień email"
                onCancel={this.cancel.bind(this)}
                onConfirm={this.changeEmail.bind(this)}
                cancelTitle="Anuluj"
                confirmTitle="Zmień email">
                <form>
                    <InputText label="Email" name="user.email" parent={this}/>
                </form>
            </Modal>}
            {this.state.mode === MODE_CHANGE_PASSWORD && <Modal
                title="Zmień hasło"
                onCancel={this.cancel.bind(this)}
                onConfirm={this.changePassword.bind(this)}
                cancelTitle="Anuluj"
                confirmTitle="Zmień hasło">
                <form>
                    <div className="mb-3">
                        <label className="form-label">Hasło</label>
                        <input type="password" className="form-control"
                               value={this.state.user.password}
                               onChange={this.setUserPassword.bind(this)}/>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Hasło powtórzone</label>
                        <input type="password" className="form-control"
                               value={this.state.user.passwordRepeat}
                               onChange={this.setUserPasswordRepeat.bind(this)}/>
                    </div>
                </form>
            </Modal>}
            {this.state.mode === MODE_DELETE && <Modal
                title="Usuń użytkownika"
                onCancel={this.cancel.bind(this)}
                onConfirm={this.deleteUser.bind(this)}
                cancelTitle="Anuluj"
                confirmTitle="Potwierdź">
                <form>
                    <div className="mb-3">
                        Czy na pewno chcesz usunąć użytkownika {this.state.user.displayName}?
                    </div>
                </form>
            </Modal>}
            {this.state.mode === MODE_ADD_ROLE && <Modal
                title="Dodaj rolę użytkownika"
                onCancel={this.cancel.bind(this)}
                onConfirm={this.addRole.bind(this)}
                cancelTitle="Anuluj"
                confirmTitle="Potwierdź">
                <form>
                    <div className="mb-3">
                        Czy na pewno chcesz dodać rolę {ROLE_NAMES[this.state.role]} dla
                        użytkownika {this.state.user.displayName}?
                    </div>
                </form>
            </Modal>}
            {this.state.mode === MODE_REMOVE_ROLE && <Modal
                title="Usuń rolę użytkownika"
                onCancel={this.cancel.bind(this)}
                onConfirm={this.removeRole.bind(this)}
                cancelTitle="Anuluj"
                confirmTitle="Potwierdź">
                <form>
                    <div className="mb-3">
                        Czy na pewno chcesz usunąć
                        rolę {ROLE_NAMES[this.state.role]} użytkownikowi {this.state.user.displayName}?
                    </div>
                </form>
            </Modal>}
            {this.state.mode === MODE_ACTIVATE && <Modal
                title="Aktywuj użytkownika"
                onCancel={this.cancel.bind(this)}
                onConfirm={this.activateUser.bind(this)}
                cancelTitle="Anuluj"
                confirmTitle="Potwierdź">
                <form>
                    <div className="mb-3">
                        Czy na pewno chcesz aktywować użytkownika {this.state.user.displayName}?
                    </div>
                </form>
            </Modal>}
            {this.state.mode === MODE_DEACTIVATE && <Modal
                title="Aktywuj użytkownika"
                onCancel={this.cancel.bind(this)}
                onConfirm={this.deactivateUser.bind(this)}
                cancelTitle="Anuluj"
                confirmTitle="Potwierdź">
                <form>
                    <div className="mb-3">
                        Czy na pewno chcesz deaktywować użytkownika {this.state.user.displayName}?
                    </div>
                </form>
            </Modal>}
        </main>;
    }

    renderRoles(user) {
        const elements = user.roles.map(role => <span key={role} className="badge bg-primary">
                                {ROLE_NAMES[role]}
            <i className="bi bi-x" onClick={this.startRemoveRole.bind(this, user, role)}/>
                            </span>)
        if (!user.roles.includes("ADMINISTRATOR") || !user.roles.includes("CONSULTANT") || !user.roles.includes("EXPERT")) {
            const items = [
                <option selected={true}>Dodaj rolę</option>
            ];
            if (!user.roles.includes("ADMINISTRATOR")) {
                items.push(<option value="ADMINISTRATOR">Administrator</option>);
            }
            if (!user.roles.includes("CONSULTANT")) {
                items.push(<option value="CONSULTANT">Konsultant</option>);
            }
            if (!user.roles.includes("EXPERT")) {
                items.push(<option value="EXPERT">Ekspert</option>);
            }
            elements.push(<select className="form-select form-select-sm"
                                  data-user={JSON.stringify(user)}
                                  onChange={this.startAddRole.bind(this)}>{items}</select>);
        }
        return elements;
    }

    startCreateUser() {
        this.setState(state => ({
            mode: MODE_CREATE,
            user: {
                displayName: "",
                email: "",
                password: "",
                passwordRepeat: "",
                roles: [],
                active: true,
                comment: ""
            },
            users: state.users,
            offset: state.offset,
            limit: state.limit,
            pageFirst: state.pageFirst,
            pagePrevious: state.pagePrevious,
            pageNext: state.pageNext,
            pageLast: state.pageLast
        }));
    }

    startChangeDisplayName(user) {
        this.setState({
            mode: MODE_CHANGE_DISPLAY_NAME,
            user: {
                id: user.id,
                displayName: user.displayName
            }
        });
    }

    startChangeEmail(user) {
        this.setState({
            mode: MODE_CHANGE_EMAIL,
            user: {
                id: user.id,
                email: user.email
            }
        });
    }

    startChangePassword(user) {
        this.setState({
            mode: MODE_CHANGE_PASSWORD,
            user: {
                id: user.id,
                password: "",
                passwordRepeat: ""
            }
        });
    }

    startDeleteUser(user) {
        this.setState(state => ({
            mode: MODE_DELETE,
            user: {
                id: user.id
            },
            users: state.users,
            offset: state.offset,
            limit: state.limit,
            pageFirst: state.pageFirst,
            pagePrevious: state.pagePrevious,
            pageNext: state.pageNext,
            pageLast: state.pageLast
        }));
    }

    startAddRole(event) {
        if (event.target.value) {
            this.setState({
                mode: MODE_ADD_ROLE,
                role: event.target.value,
                user: JSON.parse(event.target.dataset.user)
            });
        }
    }

    startRemoveRole(user, role) {
        this.setState({
            mode: MODE_REMOVE_ROLE,
            role: role,
            user: user
        });
    }

    startActivateUser(user) {
        this.setState({
            mode: MODE_ACTIVATE,
            user: user
        });
    }

    startDeactivateUser(user) {
        this.setState({
            mode: MODE_DEACTIVATE,
            user: user
        });
    }

    cancel() {
        this.setState(state => ({
            mode: MODE_DEFAULT,
            users: state.users,
            offset: state.offset,
            limit: state.limit,
            pageFirst: state.pageFirst,
            pagePrevious: state.pagePrevious,
            pageNext: state.pageNext,
            pageLast: state.pageLast
        }));
    }

    createUser() {
        this.setState(state => ({
            mode: MODE_DEFAULT,
            users: state.users,
            offset: state.offset,
            limit: state.limit,
            pageFirst: state.pageFirst,
            pagePrevious: state.pagePrevious,
            pageNext: state.pageNext,
            pageLast: state.pageLast
        }));
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/users", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + this.context.sessionId,
                "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            },
            body: JSON.stringify(this.state.user)
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    this.tableElement.current.loadObjects();
                } else {
                    throw Error(result.statusText);
                }
            });
    }

    changeDisplayName() {
        this.setState({
            mode: MODE_DEFAULT
        });
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/users/" + this.state.user.id + "/displayName", {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + this.context.sessionId,
                "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            },
            body: JSON.stringify(this.state.user)
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    this.tableElement.current.loadObjects();
                } else {
                    throw Error(result.statusText);
                }
            });
    }

    changeEmail() {
        this.setState({
            mode: MODE_DEFAULT
        });
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/users/" + this.state.user.id + "/email", {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + this.context.sessionId,
                "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            },
            body: JSON.stringify(this.state.user)
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    this.tableElement.current.loadObjects();
                } else {
                    throw Error(result.statusText);
                }
            });
    }

    changePassword() {
        this.setState({
            mode: MODE_DEFAULT
        });
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/users/" + this.state.user.id + "/password", {
            method: "PUT",
            headers: {
                Authorization: "Bearer " + this.context.sessionId,
                "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            },
            body: JSON.stringify(this.state.user)
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    this.tableElement.current.loadObjects();
                } else {
                    throw Error(result.statusText);
                }
            });
    }

    addRole() {
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/users/" + this.state.user.id + "/roles", {
            method: "POST",
            headers: {
                Authorization: "Bearer " + this.context.sessionId,
                "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            },
            body: JSON.stringify({role: this.state.role})
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    this.setState({mode: MODE_DEFAULT});
                    this.tableElement.current.loadObjects();
                } else {
                    throw Error(result.statusText);
                }
            });
    }

    removeRole() {
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/users/" + this.state.user.id + "/roles", {
            method: "DELETE",
            headers: {
                Authorization: "Bearer " + this.context.sessionId,
                "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            },
            body: JSON.stringify({role: this.state.role})
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    this.setState({mode: MODE_DEFAULT});
                    this.tableElement.current.loadObjects();
                } else {
                    throw Error(result.statusText);
                }
            });
    }

    deleteUser() {
        this.setState(state => ({
            mode: MODE_DEFAULT,
            users: state.users,
            offset: state.offset,
            limit: state.limit,
            pageFirst: state.pageFirst,
            pagePrevious: state.pagePrevious,
            pageNext: state.pageNext,
            pageLast: state.pageLast
        }));
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/users/" + this.state.user.id, {
            method: "DELETE",
            headers: {
                Authorization: "Bearer " + this.context.sessionId,
                "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            }
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    this.tableElement.current.loadObjects();
                } else {
                    throw Error(result.statusText);
                }
            });
    }

    activateUser() {
        this.setState({
            mode: MODE_DEFAULT
        });
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/users/" + this.state.user.id + "/activatedUser", {
            method: "POST", headers: {
                Authorization: "Bearer " + this.context.sessionId, "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            }
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    this.tableElement.current.loadObjects();
                } else {
                    throw Error(result.statusText);
                }
            });
    }

    deactivateUser() {
        this.setState({
            mode: MODE_DEFAULT
        });
        const request = new Request(process.env.REACT_APP_API_REST_URL + "/users/" + this.state.user.id + "/deactivatedUser", {
            method: "POST", headers: {
                Authorization: "Bearer " + this.context.sessionId, "Content-Type": "application/json",
                "x-tenant-id": process.env.REACT_APP_TENANT_ID
            }
        });
        fetch(request)
            .then(result => {
                if (result.ok) {
                    this.tableElement.current.loadObjects();
                } else {
                    throw Error(result.statusText);
                }
            });
    }

    setUserEmail(event) {
        let state = this.state;
        state.user.email = event.target.value;
        this.setState(state);
    }

    setUserPassword(event) {
        let state = this.state;
        state.user.password = event.target.value;
        this.setState(state);
    }

    setUserPasswordRepeat(event) {
        let state = this.state;
        state.user.passwordRepeat = event.target.value;
        this.setState(state);
    }

    hasRole(role) {
        return this.state.user.roles.includes(role);
    }

    setUserRole(event) {
        let state = this.state;
        const roleName = event.target.value;
        const roleIndex = state.user.roles.indexOf(roleName);
        if (roleIndex > -1) {
            state.user.roles.splice(roleIndex, 1);
        }
        if (event.target.checked) {
            state.user.roles.push(roleName);
        }
        this.setState(state);
    }

}

ContainerUsers.contextType = AppContext;

export default ContainerUsers;
